import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBContainer } from 'mdb-react-ui-kit';
import { FaHammer } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { api } from "../controllers/authController";
import '../Styles/productslist.css';
import Resizer from 'react-image-file-resizer';

const ITEMS_PER_PAGE = 15;

const LineBreak = ({ texto }) => (
  <p>
    {texto.split('\n').map((linha, index) => (
      <React.Fragment key={index}>
        {linha}
        <br />
      </React.Fragment>
    ))}
  </p>
);

function removerAcentos(texto) {
  return texto
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/\s+/g, "-");
}

const urlToBlob = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

const compressImage = async (imageUrl) => {
  const imageBlob = await urlToBlob(imageUrl);
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      imageBlob, // Objeto Blob da imagem
      612, // nova largura
      612, // nova altura
      'WEBP', // formato de saída
      75, // qualidade de compressão
      0, // rotação
      (uri) => {
        resolve(uri);
      },
      'base64' // formato de saída
    );
  });
};

const ProductCard = ({ product, onProductClick }) => {
  const [compressedImageUrl, setCompressedImageUrl] = useState(null);

  useEffect(() => {
    const thumbnailUrl = product.img_url.split(',')[product.thumbnail_index];
    const imageUrl = `${api}/${thumbnailUrl}`;

    compressImage(imageUrl).then((compressedImage) => {
      setCompressedImageUrl(compressedImage);
    });
  }, [product]);

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  return (
    <MDBCol md={4} className="d-flex justify-content-center" style={{ padding: '0%' }}>
      <MDBCard
        data-aos="fade-up"
        data-aos-duration="20"
        data-aos-offset="50"
        className="productCard2 shadow-0 shadow-hover"
        onClick={() => onProductClick(product)}
        style={{ cursor: 'pointer' }}
      >
        <img src={compressedImageUrl || `${api}/${product.img_url.split(',')[product.thumbnail_index]}`} alt={product.nome} />

        <div className="info-container">
          <div className="name-price-container">
            <h4 className='Product-name'>{product.nome}</h4>
            {product.valor_promo && (
              <h3 className='productCardPricePromotion'>{formatCurrency(product.valor_promo)}</h3>
            )}
          </div>
          <div className="material-price-container">
            <h5 className='productCardMaterial'>{product.material}</h5>
            {product.valor && (
              <h3 className='productCardPrice'>
                {formatCurrency(product.valor)}
              </h3>
            )}
          </div>
        </div>
      </MDBCard>
    </MDBCol>
  );
};



function ProductOverlay({ product, onClose }) {
  const [imageUrls, setImageUrls] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  useEffect(() => {
    if (product) {
      setImageUrls(product.img_url.split(','));
      setCurrentImageIndex(0);
      AOS.init();
    }

    const handlePopState = (event) => {
      if (event.state && event.state.product === product?.id) {
        onClose();
      } else if (!event.state) {
        onClose();
        window.history.replaceState({}, '', '/loja'); // Altere '/' para sua URL padrão
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);

  }, [product, onClose]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    afterChange: index => setCurrentImageIndex(index),
    customPaging: function (i) {
      return (
        <a className="thumbnail">
          <img src={api + `/${imageUrls[i]}`} alt={`thumbnail ${i}`} />
        </a>
      );
    },
  };

  const overlayClass = product ? "overlayCard-entering" : "";

  if (!product) return null;
  return (
    <div className="overlayCardBG">
      {isLightboxOpen && (
        <Lightbox
          mainSrc={api + `/${imageUrls[currentImageIndex]}`}
          nextSrc={api + `/${imageUrls[(currentImageIndex + 1) % imageUrls.length]}`}
          prevSrc={api + `/${imageUrls[(currentImageIndex + imageUrls.length - 1) % imageUrls.length]}`}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setCurrentImageIndex((currentImageIndex + imageUrls.length - 1) % imageUrls.length)
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % imageUrls.length)
          }
        />
      )}
      <button className="overlayCardBtn" onClick={onClose}><FaArrowAltCircleLeft /></button>
      <div className={`overlayCard ${overlayClass}`}>
        <MDBRow style={{ width: '100%', height: 'auto' }}>
          <MDBCol>
            {imageUrls.length > 0 && (
              <Slider {...settings} className="overlaySlider">
                {imageUrls.map((url, index) => (
                  <img
                    key={index}
                    src={`${api}/${url}`}
                    alt={`Imagem ${index}`}
                    onClick={() => {
                      setCurrentImageIndex(index);
                      setIsLightboxOpen(true);
                    }}
                    className="cursor-pointer"
                  />
                ))}
              </Slider>
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow className="overlay-desc-holder-r" style={{ marginTop: '-54%' }}>
          <MDBCol className="overlay-desc-holder">
            <h2>{product.nome}</h2>
            <h4><FaHammer style={{ border: '0.15rem solid #1e1e1e', borderRadius: '100%', padding: '3px', margin: '5px', fontSize: '1.8rem' }} /> {product.material}</h4>
            <h4><FaBars style={{ border: '0.15rem solid #1e1e1e', borderRadius: '100%', padding: '3px', margin: '5px', fontSize: '1.8rem' }} /> {product.categoria}</h4>
            <MDBRow style={{ width: '100%' }}>
              <MDBCol className="overlay-desc">
                <LineBreak texto={product.descricao} />
                <a className="overlayWppBtn" rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5511958380700">Entrar em contato via Whatsapp  <FaWhatsapp /></a>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </div>
      <div className="closeOverlayCard" onClick={onClose}>

      </div>
    </div>
  );
}

function ProductPage({ productData }) {
  const [imageUrls, setImageUrls] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  useEffect(() => {
    if (productData) {
      setImageUrls(productData.img_url.split(','));
      setCurrentImageIndex(0);
      AOS.init();
    }

  }, [productData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    afterChange: index => setCurrentImageIndex(index),
    customPaging: function (i) {
      return (
        <a className="thumbnail">
          <img src={api + `/${imageUrls[i]}`} alt={`thumbnail ${i}`} />
        </a>
      );
    },
  };

  return (
    <div>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={api + `/${imageUrls[currentImageIndex]}`}
          nextSrc={api + `/${imageUrls[(currentImageIndex + 1) % imageUrls.length]}`}
          prevSrc={api + `/${imageUrls[(currentImageIndex + imageUrls.length - 1) % imageUrls.length]}`}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setCurrentImageIndex((currentImageIndex + imageUrls.length - 1) % imageUrls.length)
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % imageUrls.length)
          }
        />
      )}
      <div>
        <MDBRow style={{ width: '100%', height: 'auto' }}>
          <MDBCol>
            {imageUrls.length > 0 && (
              <Slider {...settings} className="overlaySlider">
                {imageUrls.map((url, index) => (
                  <img
                    key={index}
                    src={`${api}/${url}`}
                    alt={`Imagem ${index}`}
                    onClick={() => {
                      setCurrentImageIndex(index);
                      setIsLightboxOpen(true);
                    }}
                    className="cursor-pointer"
                  />
                ))}
              </Slider>
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow className="overlay-desc-holder-r" style={{ marginTop: '-54%' }}>
          <MDBCol className="overlay-desc-holder">
            <h2>{productData.nome}</h2>
            <h4><FaHammer style={{ border: '0.15rem solid #1e1e1e', borderRadius: '100%', padding: '3px', margin: '5px', fontSize: '1.8rem' }} /> {productData.material}</h4>
            <h4><FaBars style={{ border: '0.15rem solid #1e1e1e', borderRadius: '100%', padding: '3px', margin: '5px', fontSize: '1.8rem' }} /> {productData.categoria}</h4>
            <MDBRow style={{ width: '100%' }}>
              <MDBCol className="overlay-desc">
                <LineBreak texto={productData.descricao} />
                <a className="overlayWppBtn" rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5511958380700">Entrar em contato via Whatsapp  <FaWhatsapp /></a>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  );
}

const Pagination = ({ totalPaginas, paginaAtual, onPageChange }) => {
  const pages = [];

  for (let i = 1; i <= totalPaginas; i++) {
    pages.push(i);
  }

  return (
    <div className="pagination-controls">
      {paginaAtual !== 1 && (
        <button onClick={() => onPageChange(paginaAtual - 1)}>&lt; Anterior</button>
      )}

      {pages.map(page => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`pagination-button ${paginaAtual === page ? 'active' : ''}`}
        >
          {page}
        </button>
      ))}

      {paginaAtual !== totalPaginas && (
        <button onClick={() => onPageChange(paginaAtual + 1)}>Próximo &gt;</button>
      )}
    </div>
  );
};

const ListaProdutos = ({ filtroCategoria, filtroMaterial }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const carregarProdutos = async () => {
      setLoading(true);

      try {
        const response = await fetch(`${api}/listarProdutos`);
        const data = await response.json();
        setAllProducts(data.produtos);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
        setLoading(false);
      }
    };

    carregarProdutos();
  }, []);

  useEffect(() => {
    const filtrarProdutos = () => {
      let produtosFiltrados = allProducts;

      if (filtroCategoria) {
        produtosFiltrados = produtosFiltrados.filter(product => product.categoria === filtroCategoria);
      }

      if (filtroMaterial) {
        produtosFiltrados = produtosFiltrados.filter(product => product.material === filtroMaterial);
      }

      return produtosFiltrados;
    };

    const produtosFiltrados = filtrarProdutos();
    setFilteredProducts(produtosFiltrados);
    setTotalPaginas(Math.ceil(produtosFiltrados.length / ITEMS_PER_PAGE));
    setPaginaAtual(1);
  }, [allProducts, filtroCategoria, filtroMaterial]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    const produtoNameSemAcento = removerAcentos(product.nome);
    const encodedProdutoName = encodeURIComponent(produtoNameSemAcento);
    navigate(`/produto/${encodedProdutoName}?id=${product.id}`);
  };

  const handleCloseOverlay = () => {
    setSelectedProduct(null);
    sessionStorage.removeItem('productOverlayState');
    navigate('/loja');
  };

  const handlePrevPage = () => {
    if (paginaAtual > 1) {
      setPaginaAtual(paginaAtual - 1);
    }
  };

  const handleNextPage = () => {
    if (paginaAtual < totalPaginas) {
      setPaginaAtual(paginaAtual + 1);
    }
  };

  const startIndex = (paginaAtual - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const produtosPaginados = filteredProducts.slice(startIndex, endIndex);

  return (
    <MDBContainer>
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <>
          <MDBRow className="justify-content-center align-items-center products-list-row">
            {produtosPaginados.map((product) => (
              <ProductCard key={product.id} product={product} onProductClick={handleProductClick} />
            ))}
          </MDBRow>

          {selectedProduct && <ProductOverlay product={selectedProduct} onClose={handleCloseOverlay} />}

          <MDBRow className="align-items-center justify-content-center">
            {totalPaginas > 1 && (
              <MDBCol md={6}>
                <Pagination
                  totalPaginas={totalPaginas}
                  paginaAtual={paginaAtual}
                  onPageChange={(page) => setPaginaAtual(page)}
                />
              </MDBCol>
            )}
            {totalPaginas === 1 && (
              <MDBCol md={4}>
                <p className="text-center pagination-tip">
                  <i>Exibindo todos os produtos disponíveis.</i>
                </p>
              </MDBCol>
            )}
          </MDBRow>

        </>
      )}
    </MDBContainer>
  );
};

export default ListaProdutos;
export { ProductPage };