import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import { api, getTokenFromLocalStorage } from "../controllers/authController";

function DashboardPromo() {
    const [promos, setPromos] = useState([]);
    const [selectedPromo, setSelectedPromo] = useState(null);
    const [newPromo, setNewPromo] = useState({ titulo: '', info: '', data: '' });
    const [generatedUrl, setGeneratedUrl] = useState('');

    useEffect(() => {
        fetchPromos();
    }, []);

    const fetchPromos = async () => {
        try {
            const response = await fetch(`${api}/promos`);
            const data = await response.json();
            setPromos(data.promos);
        } catch (error) {
            console.error('Erro ao buscar promoções:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPromo({ ...newPromo, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = getTokenFromLocalStorage();
        if (!token) {
            alert('Usuário não autorizado.');
            return;
        }

        try {
            const method = selectedPromo ? 'PUT' : 'POST';
            const url = selectedPromo ? `${api}/updatePromo/${selectedPromo.id}` : `${api}/createPromo`;
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,
                },
                body: JSON.stringify(newPromo),
            });

            if (response.ok) {
                const result = await response.json();
                alert('Promoção salva com sucesso!');
                setNewPromo({ titulo: '', info: '', data: '' });
                setSelectedPromo(null);
                fetchPromos();

                if (result.codigo) {
                    setGeneratedUrl(`${window.location.origin}/?codigo=${result.codigo}`);
                }
            } else {
                alert('Erro ao salvar promoção.');
            }
        } catch (error) {
            console.error('Erro ao salvar promoção:', error);
            alert('Erro ao salvar promoção.');
        }
    };

    const handleEdit = (promo) => {
        setSelectedPromo(promo);
        setNewPromo({ titulo: promo.titulo, info: promo.info, data: promo.data });
    };

    const handleDelete = async (id) => {
        const token = getTokenFromLocalStorage();
        if (!token) {
            alert('Usuário não autorizado.');
            return;
        }

        try {
            const response = await fetch(`${api}/deletePromo/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `${token}`,
                },
            });

            if (response.ok) {
                alert('Promoção deletada com sucesso!');
                fetchPromos();
            } else {
                alert('Erro ao deletar promoção.');
            }
        } catch (error) {
            console.error('Erro ao deletar promoção:', error);
            alert('Erro ao deletar promoção.');
        }
    };

    return (
        <MDBContainer className="dashboard">
            <MDBRow className="dashboardRow">
                <h2>GERENCIAMENTO DE PROMOÇÕES</h2>
                <form onSubmit={handleSubmit} className="dashboardForm">
                    <MDBCol className="dashboardCol">
                        <h6>{selectedPromo ? 'Editar Promoção' : 'Adicionar Nova Promoção'}</h6>
                        <MDBRow className="box-section">
                            <MDBCol md={12}>
                                <div className="mb-4 dashboardInput">
                                    <label className="description-text">Título</label>
                                    <input
                                        name="titulo"
                                        type="text"
                                        placeholder="Título da Promoção"
                                        value={newPromo.titulo}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-4 dashboardInput">
                                    <label className="description-text">Informações</label>
                                    <textarea
                                        name="info"
                                        rows={3}
                                        placeholder="Informações sobre a promoção"
                                        value={newPromo.info}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-4 dashboardInput">
                                    <label className="description-text">Data</label>
                                    <input
                                        name="data"
                                        type="datetime-local"
                                        value={newPromo.data}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <MDBBtn className="confirmBtn" type="submit">
                                    {selectedPromo ? 'Atualizar Promoção' : 'Adicionar Promoção'}
                                </MDBBtn>
                                {generatedUrl && (
                                    <div className="mt-3">
                                        <label>URL Gerada:</label>
                                        <p>{generatedUrl}</p>
                                    </div>
                                )}
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </form>
            </MDBRow>
            <MDBRow>
                <h4>Promoções Cadastradas</h4>
                <MDBTable striped>
                    <MDBTableHead>
                        <tr>
                            <th>Código</th>
                            <th>Título</th>
                            <th>Informações</th>
                            <th>Data</th>
                            <th>Ações</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {promos.map((promo) => (
                            <tr key={promo.id}>
                                <td>{`${window.location.origin}/?codigo=${promo.codigo}`}</td>
                                <td>{promo.titulo}</td>
                                <td>{promo.info}</td>
                                <td>{new Date(promo.data).toLocaleString()}</td>
                                <td>
                                    <MDBBtn className='promo-edit-btn' onClick={() => handleEdit(promo)}>
                                        Editar
                                    </MDBBtn>
                                    <MDBBtn className='promo-delete-btn' onClick={() => handleDelete(promo.id)}>
                                        Deletar
                                    </MDBBtn>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBRow>
        </MDBContainer>
    );
}

export default DashboardPromo;
