import { useEffect } from 'react';
import Navbar, { MobileNavbar } from '../component/navbar'
import Footer from '../component/footer'
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import serviceImg1 from '../img/services-img/serviceimg1.jpg';
import serviceImg2 from '../img/services-img/serviceimg2.jpg';
import serviceImg3 from '../img/services-img/serviceimg4.jpg';
import serviceImg4 from '../img/services-img/serviceimg3.jpg';
import serviceImg5 from '../img/services-img/serviceimg5.jpg';
import serviceImg6 from '../img/services-img/serviceimg6.jpg';
import { MdRecycling } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Servicos () {
    useEffect(() => {
        document.title = "Servicos | Garagem Conceito"
        window.scrollTo(0,0);
        AOS.init();

    }, [])
    return (
        <div>
            <Navbar />
            <MobileNavbar />
            <div className='header-separator' style={{width:'100%', paddingTop:'5rem' ,paddingBottom:'0.5rem', paddingBottom:'2rem', backgroundColor:'#fff'}}><b>Serviços</b></div>

            <MDBContainer>
                <MDBRow className='justifyCenter' >
                    <MDBCol className='servicesContent'>
                        <h1 data-aos="fade-up" data-aos-duration="1000" className='contentTitle'>Reforma de Sofás, Conchas e Cadeiras</h1>
                        <MDBRow className='justifyCenter' data-aos="fade-up" data-aos-duration="1000">
                            <div className='serviceImg bg-image hover-zoom'><img src={serviceImg1} alt="Imagem 1"></img></div>
                            <div className='serviceImg bg-image hover-zoom'><img src={serviceImg2} alt="Imagem 2"></img></div>
                            <div className='serviceImg bg-image hover-zoom'><img src={serviceImg3} alt="Imagem 3"></img></div>
                            <div className='serviceImg bg-image hover-zoom'><img src={serviceImg4} alt="Imagem 4"></img></div>
                            <p>Esses são móveis tão importantes para qualquer ambiente, que quando estão gastos ou quebradiços, é preciso recuperá-los com urgência. Com a gente você faz isso com muita tranquilidade e tem um resultado que faz parecer que as mobílias são novas!</p>
                            <p>Realizamos reformas em mesas, cadeiras, poltronas e sofás, resgatamos a beleza dos seus móveis artesanais com fibra sintética ou corda náutica.</p>
                        </MDBRow>
                        <h1 data-aos="fade-up" data-aos-duration="1000" className='contentTitle'>Reforma de Espreguiçadeiras</h1>
                        <MDBRow data-aos="fade-up" data-aos-duration="1000" className='justifyCenter' style={{paddingBottom:'10vh'}}>
                            <div className='serviceImg bg-image hover-zoom'><img src={serviceImg5} alt="Imagem 5"></img></div>
                            <div className='serviceImg bg-image hover-zoom'><img src={serviceImg6} alt="Imagem 6"></img></div>
                            <p data-aos="fade-up" data-aos-duration="1000">Aproveitar seu momento de lazer em uma espreguiçadeira velha não é agradável e nem deixa seu ambiente bonito, certo?</p>
                            <p data-aos="fade-up" data-aos-duration="1000">Te entendemos muito bem! Nós da Garagem Conceito, realizamos a restauração de espreguiçadeiras para recuperar a beleza e o conceito do seu móvel, escolha entre a fibra sintética ou corda náutica.</p>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <MDBContainer style={{maxWidth:'100%'}}>
                <MDBRow className='contact-section-holder'>
                <MDBCol md={7} className='about-recycle-section' data-aos="fade-right" data-aos-offset="200">
                        <h1>Não jogue fora, restaure <MdRecycling style={{verticalAlign:'text-top', color:'#20AA20'}} /></h1>
                        <b>
                            <p>Solicite o seu orçamento através do nosso Whatsapp ou E-mail.</p>
                        </b>
                        <p>Retiramos em toda Região do ABC. Em outras regiões, combinamos o frete de acordo com a distância de quilometragem.</p>
                    </MDBCol>
                    <MDBCol id='Contato' className='contact-recycle-btns' data-aos="fade-left" data-aos-offset="350"> 
                        <div className='tel-btns'>
                            <h3>Entre em contato conosco!</h3>
                            <a className='wppContactBtnHome' href='https://api.whatsapp.com/send?phone=5511958380700'>Entre em contato via Whatsapp</a>
                        </div>
                        <a className='mailContactBtnHome' href='mailto:contato@garagemdasfibras.com.br'><CiMail style={{fontSize:'1.5rem'}}/> Entre em contato via E-Mail </a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </div>
    )
}

export default Servicos;