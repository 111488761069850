import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../Styles/gmap.css';

function Gmap() {
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <MDBContainer fluid className="gmap-container">
      <MDBRow>
        <MDBCol data-aos="fade-up" data-aos-offset="350" className="text-center">
          <h2>
            Venha nos <span className="highlight">Visitar!</span>
          </h2>
        </MDBCol>
      </MDBRow>

      <MDBRow className="gmap-content mt-4">
        <MDBCol md="6" className="map-wrapper">
          <div className="map-container" data-aos="fade-right" data-aos-offset="100">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.822609598755!2d-46.576651924663956!3d-23.753704378668118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce411f812ff11d%3A0x35687c0db9968e9d!2sEstr.%20Galv%C3%A3o%20Bueno%2C%204647%20-%20Batistini%2C%20S%C3%A3o%20Bernardo%20do%20Campo%20-%20SP%2C%2009842-080!5e0!3m2!1spt-BR!2sbr!4v1720620055639!5m2!1spt-BR!2sbr"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Garagem Conceito Location"
            ></iframe>
          </div>
          <div className="text-center" data-aos="fade-up" data-aos-offset="50">
            <p className="address">Estrada Galvão Bueno, 4647 Batistini - São Bernardo do Campo</p>
          </div>
        </MDBCol>
        <MDBCol md="6" className="visit-info" data-aos="fade-left" data-aos-offset="50">
          <p>
            Visite o nosso espaço e conheça nossos móveis artesanais únicos e personalizados.
            Veja de perto o trabalho dos nossos artesãos e encante-se com a qualidade e beleza
            de nossas peças. Estamos esperando por você!
          </p>
          <button
            className="visit-button"
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send?phone=5511958380700&text=Ol%C3%A1,%20eu%20gostaria%20de%20agendar%20uma%20visita',
                '_blank'
              )
            }
          >
            Agendar uma visita
          </button>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Gmap;
