import { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import { IoMdLogIn } from "react-icons/io";
import { IoMailOpenOutline } from "react-icons/io5";
import { IoMdLock } from "react-icons/io";
import { TiWarningOutline } from "react-icons/ti";
import '../index.css';
import { login } from "../controllers/authController";


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginWarning, setLoginWarning] = useState({ status: false, message: '' });
    const [loginLoading, setLoginLoading] = useState(false);

    useEffect(() => {
        document.title = 'Acessar - Garagem Conceito'
    }, [])

    const handleLogin = async () => {
        if (!loginLoading) {
            setLoginLoading(true);
            const response = await login(username, password);
            if (response.success) {
                setLoginWarning({ status: false, message: '' })
                window.location.href = '/dashboard';
                setLoginLoading(false);
            } else {
                setLoginWarning({ status: true, message: response.message })
                setLoginLoading(false);
            }
        }
    }

    return (
        <MDBContainer className='scrollingBg zizag-bg' fluid>
            <MDBRow className="justify-content-center align-items-center height-100-vh">
                <MDBCol md={4}>
                    <MDBCard style={{ borderRadius: '30px' }}>
                        <MDBCardBody>
                            <h1 style={{ fontSize: '1.2rem' }}>
                                <span style={{ padding: '0.5rem 1rem', borderRadius: '30px', color: '#fD5508', backgroundColor: '#fD550844', display: 'inline-flex', marginBottom: '1rem', justifyContent: 'center', alignItems: 'center' }}>
                                    <IoMdLogIn style={{ marginRight: '0.5rem' }} size={'1.5rem'} />Fazer login
                                </span>
                            </h1>
                            <p style={{ fontSize: '0.9rem', fontWeight: 400 }}>Para visualizar e gerenciar seus produtos cadastrados, por favor, insira suas informações de login nos campos
                                abaixo.
                            </p>
                            <label style={{ fontWeight: 600, fontSize: '0.8rem' }} className="d-flex align-items-center"><IoMailOpenOutline style={{ marginRight: '0.5rem', color: '#fD5508' }} size={'1rem'} />Seu usuário:</label>
                            <input type="text" name='user' placeholder="digite aqui seu usuário" id='user' style={{ borderRadius: '10px' }} className="form-control mb-2 mt-1" onChange={e => setUsername(e.target.value)} />

                            <label style={{ fontWeight: 600, fontSize: '0.8rem' }} className="d-flex align-items-center"><IoMdLock style={{ marginRight: '0.5rem', color: '#fD5508' }} size={'1rem'} />Sua senha: </label>
                            <input type="password" name='password' placeholder="digite aqui sua senha" style={{ borderRadius: '10px' }} id='password' className="form-control mb-3 mt-1" onChange={e => setPassword(e.target.value)} />

                            {loginWarning.status && <p style={{ fontSize: '0.9rem', color: 'red' }}><TiWarningOutline style={{ marginRight: '0.5rem' }} />{loginWarning.message}</p>}

                            <MDBBtn style={{ backgroundColor: '#fD5508', borderRadius: '25px', textTransform: 'initial', fontSize: '0.9rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} onClick={handleLogin}>
                                {!loginLoading ?
                                    'Acessar Dashboard'
                                    :
                                    <MDBSpinner color='light' size="sm">
                                        <span className='visually-hidden'>Loading...</span>
                                    </MDBSpinner>
                                }
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export { Login };