import { useEffect, useState, useLayoutEffect } from 'react';
import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Navbar, { MobileNavbar } from '../component/navbar';
import Footer from '../component/footer';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';
import { MdOutlineChair } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { GaragemConceito } from "../component/Icons";
import { api } from "../controllers/authController";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../Styles/produto.css';

const LineBreak = ({ texto }) => (
    <p>
        {texto.split('\n').map((linha, index) => (
            <React.Fragment key={index}>
                {linha}
                <br />
            </React.Fragment>
        ))}
    </p>
);

const Produto = () => {
    const [mainSwiper, setMainSwiper] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [iphotoIndex, setiPhotoIndex] = useState(0);
    const [produto, setProduto] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]); // Novo estado para produtos relacionados
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const produtoId = params.get('id');
    const produtoName = params.get('produtoName');
    const [key, setKey] = useState(0);
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProduto = async () => {
            try {
                const response = await fetch(`${api}/produto/${produtoId}`);
                if (!response.ok) {
                    throw new Error('Erro ao carregar o produto');
                }
                const data = await response.json();
                setProduto(data.produto[0]); // Access the first produto in the array
                console.log(data.produto[0].name);
                AOS.init();
            } catch (error) {
                console.error('Erro ao buscar os dados do produto:', error);
            }
        };

        fetchProduto();
    }, [produtoId]);

    useEffect(() => {
        const fetchRelatedProducts = async () => {
            try {
                const response = await fetch(`${api}/listarProdutos`);
                if (!response.ok) {
                    throw new Error('Erro ao carregar produtos');
                }
                const data = await response.json();
                if (produto) {
                    setRelatedProducts(data.produtos.filter(p => p.categoria === produto.categoria && p.id !== produtoId)); // Filtra produtos pela categoria e exclui o produto atual
                }
            } catch (error) {
                console.error('Erro ao buscar produtos relacionados:', error);
            }
        };

        if (produto) {
            fetchRelatedProducts();
        }
    }, [produto, produtoId]);

    const handleProductClick = (product) => {
        const produtoNameSemAcento = removerAcentos(product.nome);
        const encodedProdutoName = encodeURIComponent(produtoNameSemAcento);
        navigate(`/produto/${encodedProdutoName}?id=${product.id}`);
    };

    function removerAcentos(texto) {
        return texto
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .replace(/\s+/g, "-");
    }

    useEffect(() => {
        if (produto) {
            const imagesLoaded = produto.img_url.split(',').map(imgUrl => `${api}/${imgUrl}`);
            Promise.all(imagesLoaded.map(src => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = resolve;
                    img.src = src;
                });
            })).then(() => {
                setKey(prevKey => prevKey + 1);
            });
        }
    }, [produto]);

    useEffect(() => {
        const updatePhotoIndexWithDelay = async () => {
            if (isOpen && produto) {
                await delay(100);
                setPhotoIndex(photoIndex);
            }
        };

        updatePhotoIndexWithDelay();
    }, [isOpen, produto]);

    useEffect(() => {
        if (isOpen) {
            setKey(prevKey => prevKey + 1);
        }
    }, [isOpen]);

    useEffect(() => {
        AOS.init();
    }, []);

    const handleSlideChange = (swiper) => {
        const currentIndex = swiper.realIndex;
        setPhotoIndex(currentIndex + 1);
    };

    useLayoutEffect(() => {
        if (produto && isOpen) {
            setPhotoIndex(photoIndex);
        }
    }, [produto, isOpen]);

    if (!produto) {
        return null;
    }

    return (
        <div>
            <Navbar />
            <MobileNavbar />
            <MDBContainer className='produto-container'>
                <MDBCol fluid md={6}>
                    <MDBRow style={{ padding: '0', margin: '0' }}>
                        <div className='produto-title' style={{ paddingTop: '2rem', paddingBottom: '0.5rem' }}>
                            <h1>{produto.nome}</h1>
                        </div>
                        <div style={{ backgroundColor: '#e1e1e1', padding: '1rem', borderRadius: '25px' }} className='carousel-custom'>
                            <Swiper
                                onSwiper={setMainSwiper}
                                className='swiper-produto-container'
                                autoplay={{ delay: 1200, disableOnInteraction: false }}
                                centeredSlides={true}
                                slidesPerView="1"
                                spaceBetween={100}
                                loop={false}
                                modules={[Navigation, Pagination, Thumbs]}
                                navigation={true}
                                onSlideChange={(swiper) => {
                                    const currentIndex = swiper.realIndex;
                                    setPhotoIndex(currentIndex);
                                    setiPhotoIndex(currentIndex);
                                }}
                            >
                                {produto.img_url.split(',').map((imageUrl, index) => (
                                    <SwiperSlide key={index + 1} className='produto-item'>
                                        <img src={`${api}/${imageUrl}`} alt={`Imagem ${index}`} style={{ objectFit: 'cover' }} onClick={() => { setIsOpen(true); }} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="thumbnails-container">
                                {produto.img_url.split(',').map((imageUrl, index) => (
                                    <img
                                        key={index}
                                        src={`${api}/${imageUrl}`}
                                        alt={`Thumbnail ${index}`}
                                        className="thumbnail-item"
                                        onClick={() => {
                                            setPhotoIndex(index);
                                            mainSwiper && mainSwiper.slideTo(index);
                                        }}
                                    />
                                ))}
                            </div>

                            {isOpen && (
                                <Lightbox
                                    key={key}
                                    mainSrc={`${api}/${produto.img_url.split(',')[photoIndex]}`}
                                    nextSrc={`${api}/${produto.img_url.split(',')[(photoIndex + 1) % produto.img_url.split(',').length]}`}
                                    prevSrc={`${api}/${produto.img_url.split(',')[(photoIndex + produto.img_url.split(',').length - 1) % produto.img_url.split(',').length]}`}
                                    onCloseRequest={() => (setIsOpen(false), setPhotoIndex(iphotoIndex))}
                                    onMovePrevRequest={() => setPhotoIndex((photoIndex + produto.img_url.split(',').length - 1) % produto.img_url.split(',').length)}
                                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % produto.img_url.split(',').length)}
                                />
                            )}
                            <h4><div className="produto-icon"><GaragemConceito /> {produto.material}</div> <div className="produto-icon"><MdOutlineChair style={{ fontSize: '1.8rem' }} /> {produto.categoria}</div></h4>
                        </div>
                    </MDBRow>
                </MDBCol>
                <MDBCol fluid md={6} style={{}}>
                    <MDBRow fluid>
                        <MDBCol fluid className="overlay-desc">
                            <div className='produto-text'>
                                <h1>Informações</h1>
                                <p data-aos="fade-up" data-aos-duration="300" style={{ color: '#FD5508' }}>
                                    <LineBreak texto={produto.descricao} />
                                </p>
                                {produto.info && (
                                    <div data-aos="fade-up" data-aos-duration="300">
                                        <h1 className='mt-5'>Caracteristicas</h1>
                                        <p className='produto-infos m-0' style={{ color: '#FD5508' }}>
                                            <LineBreak texto={produto.info} />
                                        </p>
                                    </div>
                                )}
                            </div>
                            <a className="overlayWppBtn" rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5511958380700">
                                <p>Faça seu orçamento!</p>
                                <p><FaWhatsapp /> (11) 95838-0700</p>
                            </a>
                        </MDBCol>
                    </MDBRow>

                </MDBCol>
            </MDBContainer>
            <MDBContainer className='related-products-container' style={{ marginTop: '2rem' }}>
                <h2>Produtos Relacionados</h2>
                <MDBRow className='mb-6'>
                    {relatedProducts.slice(0, 4).map((relatedProduct, index) => (
                        <MDBCol key={index} md={3}>
                            <div className='related-product' onClick={() => handleProductClick(relatedProduct)}>
                                <img src={`${api}/${relatedProduct.img_url.split(',')[0]}`} alt={relatedProduct.nome} style={{ objectFit: 'cover', width: '100%', height: '200px' }} />
                                <h4>{relatedProduct.nome}</h4>
                                <div>
                                    <p>{relatedProduct.material}</p>
                                    <p>{relatedProduct.categoria}</p>
                                </div>
                            </div>
                        </MDBCol>
                    ))}
                </MDBRow>
            </MDBContainer>
            <Footer />
        </div>
    );
}

export default Produto;
