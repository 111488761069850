import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import '../Styles/promocao.css';
import promoImage from '../img/promoImage.png';
import carouselImage1 from '../img/carroselpromo/carouselImage1.png';
import carouselImage2 from '../img/carroselpromo/carouselImage2.png';
import carouselImage3 from '../img/carroselpromo/carouselImage3.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { api } from "../controllers/authController";

function Promocao() {
    const [activeImage, setActiveImage] = useState(0);
    const images = [carouselImage1, carouselImage2, carouselImage3];
    const [promoData, setPromoData] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const codigoPromo = searchParams.get('codigo');
        console.log("Código da Promoção:", codigoPromo);

        const fetchPromoData = async () => {
            try {
                let url = `${api}/promo`;
                if (codigoPromo) {
                    url = `${api}/promoByCodigo?codigo=${codigoPromo}`;
                }

                console.log("Fetching URL:", url);

                const response = await fetch(url);
                if (response.ok) {
                    const data = await response.json();
                    console.log("Dados da Promoção:", data);
                    setPromoData(data.promo);
                    const promoEndDate = new Date(data.promo.data);
                    setTimeRemaining(calculateTimeRemaining(promoEndDate));
                } else {
                    console.error('Erro ao buscar dados da promoção:', response.statusText);
                }
            } catch (error) {
                console.error('Erro ao buscar dados da promoção:', error);
            }
        };

        fetchPromoData();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveImage((prevImage) => (prevImage + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        if (promoData) {
            const interval = setInterval(() => {
                const promoEndDate = new Date(promoData.data);
                setTimeRemaining(calculateTimeRemaining(promoEndDate));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [promoData]);

    const calculateTimeRemaining = (endDate) => {
        const now = new Date();
        const timeDifference = endDate - now;

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);

        return {
            days: days > 0 ? days : 0,
            hours: hours > 0 ? hours : 0,
            minutes: minutes > 0 ? minutes : 0,
            seconds: seconds > 0 ? seconds : 0,
        };
    };

    if (!promoData) {
        return null; // Não renderiza nada se não houver dados de promoção
    }

    return (
        <MDBContainer className="promo-base">
            <MDBRow className="promo-content" data-aos="zoom-in" data-aos-offset="100">
                <MDBCol md="6" id='content-left' className="d-flex flex-column align-items-center justify-content-center">
                    <img src={promoImage} alt="Promoção" className="promo-img" />
                    <h2 className="promo-text">{promoData.titulo}</h2>
                    <p className='promo-desc'>{promoData.info}</p>
                    {timeRemaining && (
                        <div className="promo-timer">
                            {`${timeRemaining.days}D : ${timeRemaining.hours}H : ${timeRemaining.minutes}M : ${timeRemaining.seconds}S`}
                        </div>
                    )}
                    <MDBBtn className="promo-button">Cadastrar!</MDBBtn>
                </MDBCol>
                <MDBCol md="4" className="d-flex align-items-center justify-content-end align-self-stretch">
                    <div className="carousel-container promo-container">
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Carousel ${index}`}
                                className={`carousel-image promo-image ${index === activeImage ? 'active' : ''}`}
                            />
                        ))}
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default Promocao;
