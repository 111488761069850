import { useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard } from "mdb-react-ui-kit";
import { api, getTokenFromLocalStorage } from "../controllers/authController";
import '../index.css';
import ListaProdutosDelete from "../component/products_remove";


function DashboardRemove() {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [atualizarLista, setAtualizarLista] = useState(false);

    const notificarAtualizacaoLista = () => {
        setAtualizarLista(atual => !atual); // Alterna o estado para forçar a atualização
    };

    const handleProductSelect = (produto) => {
        setSelectedProduct(produto);
    };

    const deletarProdutoSelecionado = () => {
        if (selectedProduct && selectedProduct.id) {
            // Obter o token do localStorage
            const token = getTokenFromLocalStorage(); // Supondo que esta função retorne o token diretamente

            if (!token) {
                console.error('Token não encontrado. O usuário não está autenticado.');
                alert('Usuário não autorizado.');
                window.location.href = '/dashboard/login';
                return;
            }

            fetch(`${api}/deletarProdutos/${selectedProduct.id}`, {
                method: 'DELETE',
                headers: {
                    // Adicionar o token Bearer ao cabeçalho Authorization
                    'Authorization': `${token}`
                },
            })
                .then(response => {
                    if (response.ok) {
                        alert('Produto removido com sucesso.');
                        notificarAtualizacaoLista();
                    } else {
                        alert('Falha ao remover o produto.');
                    }
                })
                .catch(error => {
                    console.error('Erro ao deletar produto:', error);
                    alert('Erro ao tentar remover o produto.');
                });
        } else {
            alert('Nenhum produto selecionado.');
        }
    };



    return (
        <div>
            <MDBContainer className="dashboard-content-container">
                <MDBRow className="dashboardRow">
                    <h2>REMOÇÃO DE PRODUTO</h2>
                    <h6>Selecione abaixo o produto para remover</h6>
                </MDBRow>
                <MDBRow fluid style={{padding:'0', width:'100%', margin:'0'}}>
                    <ListaProdutosDelete onProductSelect={handleProductSelect} atualizarLista={atualizarLista} notificarAtualizacao={notificarAtualizacaoLista} />
                </MDBRow>
            </MDBContainer>
            <MDBContainer className="dashboard-content-container">
                <MDBRow style={{ width: '100%', margin: '0', padding: '0' }}>
                    <MDBCol>
                        <MDBCard className="dashboardRemoveCard">
                            <MDBRow style={{ display: 'flex', alignItems: 'center' }}>
                                <h2>Produto Selecionado</h2>
                                {selectedProduct && (
                                    <>
                                        <MDBCol md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={api + `/${selectedProduct.img_url.split(',')[0]}`} alt={selectedProduct.nome} style={{}} />
                                        </MDBCol>
                                        <MDBCol className="selected-remove-product" style={{ marginLeft: '2%' }}>
                                            <p>Nome: {selectedProduct.nome}</p>
                                            <p>Descrição: {selectedProduct.descricao}</p>
                                            <p>Material: {selectedProduct.material}</p>
                                        </MDBCol>
                                    </>
                                )}
                            </MDBRow>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="dashboardRow centered-button">
                    <MDBBtn style={{ padding: '1rem' }} className="confirmBtn" type="button" onClick={deletarProdutoSelecionado}>
                        Deseja Remover o Produto Selecionado?
                    </MDBBtn>
                </MDBRow>
            </MDBContainer>
            <MDBContainer>
            </MDBContainer>
        </div>
    );
}

export default DashboardRemove;