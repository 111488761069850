import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Index from './screen';
import SobreNos from './screen/sobreNos';
import Produtos from './screen/produtos';
import Servicos from './screen/servicos';
import Contato from './screen/contato';
import Dashboard from './screen/dashboard';
import { Login } from './component/dashboard_login.js';
import Produto from './screen/produto.js';
import Usuario from './screen/usuario.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Redireciona para a página principal com o código de promoção */}
        <Route
          path="/promocao"
          element={<Navigate to="/" replace />}
        />
        <Route path="/" index element={<Index />}/>
        <Route path="/sobre-nos" element={<SobreNos />}/>
        <Route path="/loja" element={<Produtos />}/>
        <Route path="/servicos" element={<Servicos />}/>
        <Route path="/contato" element={<Contato />}/>
        <Route path="/dashboard" element={<Dashboard />}/>
        <Route path="/dashboard/login" element={<Login />}/>
        <Route path="/cadastre-e-ganhe" element={<Usuario />}/>
        <Route path='/produto/:produtoId' element={<Produto />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
