import {useEffect} from 'react';
import Navbar, { MobileNavbar } from '../component/navbar'
import Footer from '../component/footer'
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import zigZag from '../img/zigzag-bg.svg';
import Logo from '../img/Logo-1.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

function SobreNos () {
    useEffect(() => {
        document.title = "Sobre Nós | Garagem Conceito"
        window.scrollTo(0,0);
        AOS.init();
    }, [])
    return (
        <div>
            <Navbar />
            <MobileNavbar />
            <MDBContainer className='about-us-container'>
                <MDBRow style={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent:'center', padding:'25px', backgroundImage:(`url(${zigZag})`), width:'100%', marginTop:'75px'}}>
                    <img src={Logo} alt='imagem de sobre nós 1' style={{margin:'15px', width:'500px'}} data-aos-duration="1000" data-aos="flip-right"></img>
                </MDBRow>
                <div className='header-separator' style={{width:'100%', paddingTop:'2rem' ,paddingBottom:'0.5rem', backgroundColor:'#fff'}}><b>Sobre Nós</b></div>

                <MDBRow style={{maxWidth:'100%', width:'100%', marginTop:'1vh', marginBottom:'5vh'}}>
                    <MDBCol style={{width:'100%', display:'flex', justifyContent:'center'}}>
                        <div className='about-us-text'>
                            <h3 data-aos="fade-up" data-aos-duration="300" style={{color:'#FD5508'}}>O sonho dos móveis artesanais sob medida é uma realidade</h3>
                            <p data-aos="fade-up" data-aos-duration="300">A nossa história começa em 2013, com o Wilson descobrindo, conhecendo e se aprofundando sobre o mercado de móveis artesanais. Ao se encantar com o segmento, o então representante comercial, iniciou representando uma empresa e montou um showroom na garagem da própria casa. </p>
                            <p data-aos="fade-up" data-aos-duration="300">Com o tempo, o reconhecimento pelos móveis foi aparecendo, e o espaço que não tinha nome, recebeu o apelido carinhoso de quem aprovava o serviço: Garagem dos Móveis de Fibras. E não é que ficou? Assim, Wilson deu continuidade a essa história e colocou nome a esse grande sonho: <b style={{color:'#FD5508'}}>Garagem das Fibras.</b> </p>
                            <p data-aos="fade-up" data-aos-duration="300">Com o aumento de demandas, entendeu a grandiosidade desse mercado e abriu mão da vida de representante comercial para então ser um empreendedor. Montando o seu próprio espaço de produção dos móveis em São Bernardo do Campo. E expandimos os nossos serviços da loja de Garagem no Baeta Neves para a cidade de Santo André, onde ficamos por 5 anos. Porém, fomos surpreendidos com a pandemia e, assim como muitas empresas, tivemos que recuar fechando a loja em Santo André. Atualmente, mantemos a loja e a fábrica no endereço atual.</p>
                            <p data-aos="fade-up" data-aos-duration="300">Esse impacto nos fez repensar o contato com os nossos clientes, e entendemos a necessidade de aproximá-los da produção de seus móveis sob medida. Agora todo o passo a passo pode ser acompanhado desde a montagem das estruturas em alumínio, o trabalho artesanal do revestimento dos móveis, até a costura. Processo realizado pelos nossos artesãos e costureiras.</p>
                            <p data-aos="fade-up" data-aos-duration="300">A partir de então, resolvemos focar em quem valoriza a produção de móveis artesanais personalizados de alta qualidade, atribuindo no conforto do cliente e no design de onde o móvel é inserido. Entregamos peças de beleza única, produzidas com materiais altamente valorizados no universo da decoração, como a <b style={{color:'#FD5508'}}>fibra sintética, a corda náutica e o tricô náutico.</b></p>
                            <p data-aos="fade-up" data-aos-duration="300">Compreendendo o nosso conceito, encontramos a nossa identidade e definimos quem somos. Hoje somos a <b style={{color:'#FD5508'}}>Garagem Conceito.</b></p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </div>
    )
}

export default SobreNos;