import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar, { MobileNavbar } from '../component/navbar'
import Footer from '../component/footer'
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import ListaProdutos from '../component/products';
import { BiFilterAlt } from "react-icons/bi";
import AOS from 'aos';
import 'aos/dist/aos.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Produtos() {
    const query = useQuery();
    const categoriaFiltro = query.get("categoria");
    const materialFiltro = query.get("material");
    const [filtroCategoria, setFiltroCategoria] = useState(categoriaFiltro || '');
    const [filtroMaterial, setFiltroMaterial] = useState(materialFiltro || '');

    useEffect(() => {
        document.title = "Loja | Garagem Conceito";
        window.scrollTo(0, 0);
        AOS.init();

        if (categoriaFiltro) {
            setFiltroCategoria(categoriaFiltro);
        }

        if (materialFiltro) {
            setFiltroMaterial(materialFiltro);
        }

    }, [categoriaFiltro, materialFiltro]);

    return (
        <div>
            <Navbar />
            <MobileNavbar />
            <MDBContainer fluid style={{ padding: '4%' }}>
                <MDBRow className='product-title' style={{ marginTop: '4rem' }}>
                    <MDBCol size={12}>
                        <h2 data-aos="fade-down">Explore nossa coleção exclusiva de móveis de alta qualidade!</h2>
                        <h4 data-aos="fade-up" data-aos-duration="1000">Descubra o requinte e a elegância em cada peça.</h4>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="filter-holder">
                    <MDBCol size={12} className='filter-container'>
                        <div className='filter-desc'>
                            <h5>Encontre o que procura</h5>
                            <p>Utilize os filtros para encontrar os produtos que melhor atendam às suas necessidades. Selecione uma categoria e um material para refinar sua busca.</p>
                        </div>
                        <div className='filterMain'>
                            <h5 className='d-flex align-items-center'><BiFilterAlt /> Filtrar por</h5>
                            <div className='filter-selects'>
                                <select value={filtroCategoria} onChange={e => setFiltroCategoria(e.target.value)}>
                                    <option value="">Todas Categorias</option>
                                    <option value="Sofá">Sofás</option>
                                    <option value="Balanço">Balanços</option>
                                    <option value="Espreguiçadeira">Espreguiçadeiras</option>
                                    <option value="Poltrona">Poltronas</option>
                                    <option value="Banqueta">Banquetas</option>
                                    <option value="Concha">Chaises e Conchas</option>
                                    <option value="Cadeira">Cadeiras</option>
                                    <option value="Mesa">Mesas</option>
                                    <option value="Balcão">Aparadores e Balcões</option>
                                    <option value="Mesa de Centro">Mesas de Centro</option>
                                    <option value="Banco">Puffs e Bancos</option>
                                    <option value="Ombrelone">Ombrelones</option>
                                </select>
                                <select value={filtroMaterial} onChange={e => setFiltroMaterial(e.target.value)}>
                                    <option value="">Todos Materiais</option>
                                    <option value="Corda Náutica">Corda / Tricô Náutico</option>
                                    <option value="Fibra Sintética">Fibra Sintética</option>
                                    <option value="Industrial">Industrial</option>
                                    <option value="Madeira">Madeira</option>
                                </select>
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>
                <ListaProdutos filtroCategoria={filtroCategoria} filtroMaterial={filtroMaterial} />
            </MDBContainer>

            <Footer />
        </div>
    )
}

export default Produtos;
