import { MDBRow, MDBCol, MDBContainer } from 'mdb-react-ui-kit';
import Logo1 from '../img/Logo-1.png';
import FooterDetail from '../img/FooterDetail.svg';
import { FaInstagram } from "react-icons/fa6";
import { FaPinterest } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import '../Styles/footer.css'; // Importe o arquivo CSS para os estilos do footer

function Footer() {
    return (
        <>
            <MDBContainer className="footer-holder" style={{ paddingTop: '2rem', backgroundColor: '#FFF', maxWidth: '100%', bottom: '0' }}>
                <MDBRow className="mobileFooter" style={{ display: 'flex', maxHeight: '100%', height: '100%', alignContent: 'center', fontFamily: 'M PLUS 1', color: '#1e1e1e', fontSize: '1rem', fontWeight: '500' }}>
                    <MDBCol className="mobileFooterImg">
                        <MDBRow>
                            <img src={Logo1} alt="Logotipo" style={{ height: '50%', width: '50%' }} />
                        </MDBRow>
                    </MDBCol>
                    <MDBCol className='footerSocial' style={{ fontSize: '3rem', textAlign: 'center' }}>
                        <a className="social-icon"  href="https://www.instagram.com/garagemconceito/"><FaInstagram /></a>
                        <a href="#"  className="social-icon"><FaPinterest /></a>
                        <a href="https://www.facebook.com/garagemdasfibras1" className="social-icon"><FaFacebook /></a>
                    </MDBCol>
                    <MDBCol>
                        <MDBRow className='footerLinks' style={{ textAlign: 'end', fontSize: '1.3rem' }}>
                            <a href="tel:1143476238"><BsTelephone /> (11) 4347-6238</a>
                            <a href="https://api.whatsapp.com/send?phone=5511958380700" target='_blank' rel='noreferrer'><FaWhatsapp /> (11) 95838-0700</a>
                            <a href="mailto:contato@garagemdasfibras.com.br"><CiMail /> contato@garagemdasfibras.com.br</a>
                        </MDBRow>
                    </MDBCol>
                    <p style={{ textAlign: 'center' }}><FiMapPin /> Estrada Galvão Bueno, 4647 Batistini - São Bernardo do Campo</p>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="footerCopyright">
                        © 2024 Garagem Conceito
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
}

export default Footer;
