import React, { useEffect, useState } from 'react';
import Navbar, { MobileNavbar } from '../component/navbar';
import Footer from '../component/footer';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBBtn } from 'mdb-react-ui-kit';
import { useLocation, useHistory } from 'react-router-dom';
import mesaBG from '../img/index-img/mesaBG.png';
import cadeiraBG from '../img/index-img/cadeiraBG.png';
import sofaBG from '../img/index-img/sofaBG.png';
import BannerVideo1 from '../img/BannerVideo1.mp4';
import 'swiper/css';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import AOS from 'aos';
import { api } from "../controllers/authController";
import 'aos/dist/aos.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import GaragemIcon from '../img/LogoWithOutSquare.png';
import Gmap from '../component/gmap';
import InstagramSec from '../component/instagram';
import Promocao from './promocao';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Index() {
    const [slidesPerView, setSlidesPerView] = useState(3);
    const [slidesCentered, setSlidesCentered] = useState(true);
    const [banners, setBanners] = useState([]);
    const query = useQuery();
    const promoCode = query.get('codigo');
    const location = useLocation();

    useEffect(() => {
        document.title = "Inicio | Garagem Conceito"
        window.scrollTo(0, 0);
        AOS.init();

        const mediaQueries = [
            window.matchMedia('(max-width: 780px)'),
            window.matchMedia('(max-width: 1200px)'),
        ];

        const updateSlidesPerView = () => {
            if (mediaQueries[0].matches) {
                setSlidesPerView(1);
                setSlidesCentered(true);
            } else if (mediaQueries[1].matches) {
                setSlidesPerView(1);
                setSlidesCentered(true);
            } else {
                setSlidesPerView(3);
                setSlidesCentered(false);
            }
        };

        const handleMediaQueryChange = () => {
            updateSlidesPerView();
        };

        const fetchBanners = async () => {
            try {
                const response = await fetch(`${api}/banners`);
                const data = await response.json();
                setBanners(data.banners);
            } catch (error) {
                console.error('Erro ao buscar banners:', error);
            }
        };

        fetchBanners();
        updateSlidesPerView();

        mediaQueries.forEach(mediaQuery => {
            mediaQuery.addEventListener('change', handleMediaQueryChange);
        });

        // Rolar para o elemento se houver um hash na URL
        const hash = location.hash;
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
            mediaQueries.forEach(mediaQuery => {
                mediaQuery.removeEventListener('change', handleMediaQueryChange);
            });
        };
    }, [location]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <Navbar />
            <MobileNavbar />
            <MDBContainer className='hero' style={{ margin: '0', padding: '0', maxWidth: '100%', position: 'relative', bottom: '3rem' }}>
                <div className="banner-video-container">
                    <video className="banner-video" src={BannerVideo1} autoPlay loop muted playsInline />
                </div>
                <MDBContainer className='banner' style={{ height: '100%', maxWidth: '100%', padding: '0', zIndex: '-1', position: 'relative', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPositionY: '50%' }}>
                    <div style={{ height: '90vh' }}></div>
                </MDBContainer>
                <MDBCard className='headerCard' data-aos="fade-right">
                    <img className="logo-img-hero" src={GaragemIcon} alt="Logotipo Navbar" style={{ height: 'auto', width: '50%', zIndex: '11', paddingBottom: '5px' }} />
                    <p style={{ color: '#FFF', textAlign: 'justify', marginLeft: '12px' }}>A Garagem das Fibras agora é Garagem Conceito! Entendemos que apesar de não sermos mais a loja que começou na garagem da nossa casa, nós evoluímos! Vem conhecer mais sobre a nossa história!</p>
                    <div className='buttons-banner' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='aboutBtn'>
                            <Link to='/#contato'>
                                <p style={{ fontSize: '1.2rem', margin: '0' }}>Entre em Contato</p>
                            </Link>
                        </div>
                        <div className='materialsBtnHome viewAllBtn'>
                            <Link to='/loja'>
                                <p style={{ fontSize: '1.2rem', margin: '0' }}>Ver Produtos</p>
                            </Link>
                        </div>
                    </div>
                </MDBCard>
            </MDBContainer>

            <Promocao promoCode={promoCode} />

            <MDBContainer fluid style={{ margin: '0', padding: '2% 10%', backgroundColor: '#FFF' }}>
                <MDBRow>
                    <MDBCol md={6} data-aos="zoom-in" className='mb-3'>
                        <MDBCard className='productCard'>
                            <div className='productOverlay'></div>
                            <img src={cadeiraBG} alt='Corda Náutica' className='productImg' />
                            <div className='productInfo'>
                                <div>
                                    <h4>Corda Náutica</h4>
                                    <p>Navegue através do catálogo e descubra nossos móveis de alta qualidade em Corda Náutica.</p>
                                </div>
                                <ul className='card-list'>
                                    <li><a href='/loja?categoria=Sofá&material=Corda%20Náutica'>Sofás</a></li>
                                    <li><a href='/loja?categoria=Balanço&material=Corda%20Náutica'>Balanços</a></li>
                                    <li><a href='/loja?categoria=Espreguiçadeira&material=Corda%20Náutica'>Espreguiçadeiras</a></li>
                                    <li><a href='/loja?categoria=Poltrona&material=Corda%20Náutica'>Poltronas</a></li>
                                    <li><a href='/loja?categoria=Banqueta&material=Corda%20Náutica'>Banquetas</a></li>
                                    <li><a href='/loja?categoria=Cadeira&material=Corda%20Náutica'>Cadeiras</a></li>
                                </ul>
                                <a href='/loja?material=Corda%20Náutica'>
                                    <div className='materialsBtnHome'>
                                        <p style={{ fontSize: '1rem', margin: '0', color: '#ffff' }}>Navegar em Corda Náutica</p>
                                    </div>
                                </a>
                            </div>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md={6} className="mb-3 d-flex flex-column">
                        <MDBCol data-aos="zoom-in" className="flex-grow-1 mb-3">
                            <MDBCard className='productCard'>
                                <div className='productOverlay'></div>
                                <img src={mesaBG} alt='Fibra Sintética' className='productImg' />
                                <div className='productInfo'>
                                    <h4>Fibra Sintética</h4>
                                    <p>Navegue através do catálogo e descubra nossos móveis de alta qualidade em Fibra Sintética.</p>
                                    <a href='/loja?material=Fibra%20Sintética'>
                                        <div className='materialsBtnHome'>
                                            <p style={{ fontSize: '1rem', margin: '0', color: '#ffff' }}>Navegar em Fibra Sintética</p>
                                        </div>
                                    </a>
                                </div>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol data-aos="zoom-in" className="flex-grow-1">
                            <MDBCard className='productCard'>
                                <div className='productOverlay'></div>
                                <img src={sofaBG} alt='Industrial' className='productImg' />
                                <div className='productInfo'>
                                    <h4>Industrial</h4>
                                    <p>Navegue através do catálogo e descubra nossos móveis de alta qualidade feitos de forma Industrial.</p>
                                    <a href='/loja?material=Industrial'>
                                        <div className='materialsBtnHome'>
                                            <p style={{ fontSize: '1rem', margin: '0', color: '#ffff' }}>Navegar em Industrial</p>
                                        </div>
                                    </a>
                                </div>
                            </MDBCard>
                        </MDBCol>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

            <InstagramSec />

            <Gmap />

            <MDBContainer style={{ maxWidth: '100%' }}>
                <MDBRow className='contact-section-holder'>
                    <MDBCol md={7} className='contact-section' data-aos="fade-right" data-aos-offset="50">
                        <h1>Entre em contato conosco!</h1>
                        <p>A decoração é o que move a sua vida?</p>
                        <b></b>
                        <p>A Garagem Conceito é uma empresa especializada em móveis artesanais e decoração, o nosso conceito é dar vida aos ambientes.</p>
                        <p>Você é decorador(a) e quer tirar o seu projeto do papel? Apresente o seu estudo para nós!</p>
                        <p>Conheça os projetos que já executamos com a parceira de arquitetos.</p>
                    </MDBCol>
                    <MDBCol id='contato' className='contact-btns' data-aos="fade-left" data-aos-offset="50">
                        <MDBCol md={12} className='tel-btns'>
                            <a className='wppContactBtnHome' href='https://api.whatsapp.com/send?phone=5511958380700'>Entre em contato via Whatsapp  <p><FaWhatsapp style={{ fontSize: '1.5rem' }} /> (11) 95838-0700 </p></a>
                            <a className='phoneContactBtnHome' href='tel:1143476238'>Faça uma ligação! <p><BsTelephone style={{ fontSize: '1.5rem' }} /> (11) 4347-6238</p></a>
                        </MDBCol>
                        <MDBCol md={12}>
                            <h5>Ou se preferir</h5>
                        </MDBCol>
                        <a className='mailContactBtnHome' href='mailto:contato@garagemdasfibras.com.br'><CiMail style={{ fontSize: '1.5rem' }} /> Entre em contato via E-Mail </a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

            <Footer />
        </div >
    );
}

export default Index;
