import { useEffect, useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard } from "mdb-react-ui-kit";
import { getTokenFromLocalStorage } from "../controllers/authController";
import '../index.css';
import DashboardAdd from "../component/dashboard_add";
import DashboardEdit from "../component/dashboard_edit";
import DashboardRemove from "../component/dashboard_remove";
import DashboardBanners from "../component/dashboard_banners";
import DashboardLeads from "../component/dashboard_leads";
import DashboardPromo from "../component/dashboard_promo";
import { MdOutlineDiscount } from "react-icons/md";
import { IoTrashOutline, IoCreateOutline } from "react-icons/io5";
import { MdOutlineCreate, MdOutlineAnnouncement } from "react-icons/md";
import { TbDiscount2 } from "react-icons/tb";
import Logo from '../img/Logo-1.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Dashboard() {
    const [view, setView] = useState('dashboard');

    useEffect(() => {
        // Obter o token do localStorage
        const token = getTokenFromLocalStorage();

        if (!token) {
            console.error('Token não encontrado. O usuário não está autenticado.');
            alert('Usuário não autorizado.');
            window.location.href = '/dashboard/login';
            return;
        }

        AOS.init();
    }, [])


    return (
        <div>
            <div className="navbarDashboard">
                <MDBRow>
                    <MDBCol className="navbarDashboardCol">
                        <div onClick={() => setView('dashboard_add')}><IoCreateOutline className="navbarIcon" /></div>
                        <div onClick={() => setView('dashboard_remove')}><IoTrashOutline className="navbarIcon" /></div>
                        <div onClick={() => setView('dashboard_edit')}><MdOutlineCreate className="navbarIcon" /></div>
                        <div onClick={() => setView('dashboard_banners')}><MdOutlineAnnouncement className="navbarIcon" /></div>
                        <div onClick={() => setView('dashboard_leads')}><TbDiscount2 className="navbarIcon" /></div>
                        <div onClick={() => setView('dashboard_promo')}><MdOutlineDiscount className="navbarIcon" /></div>
                    </MDBCol>
                </MDBRow>
            </div>

            {view === 'dashboard' ?
                <MDBContainer className="dashboard dashboardMain">
                    <MDBRow className='contentTitle' style={{ marginTop: '5vh', marginLeft: '5rem', marginRight: '5rem' }}>
                        <MDBCol>
                            <h2>Seja bem vindo ao painel administrativo</h2>
                            <h4 style={{ textTransform: 'none' }}>Aqui você pode gerenciar seus produtos, criando, deletando ou editando os mesmos, selecione abaixo o painel de administração desejado.</h4>
                            <h4 style={{ textTransform: 'none' }}>Você também pode navegar utilizando os icones na barra de navegação lateral.</h4>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ marginTop: '0rem' }}>
                        <MDBCol className="d-flex justify-content-center flex-wrap">
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_add')}>
                                <IoCreateOutline style={{ fontSize: '2rem', color: '#ff5000' }}></IoCreateOutline > Adicionar Produto
                            </MDBCard>
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_remove')}>
                                <IoTrashOutline style={{ fontSize: '2rem', color: '#ff5000' }}></IoTrashOutline> Remover Produto
                            </MDBCard>
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_edit')}>
                                <MdOutlineCreate style={{ fontSize: '2rem', color: '#ff5000' }}></MdOutlineCreate> Editar Produto
                            </MDBCard>
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_banners')}>
                                <MdOutlineAnnouncement style={{ fontSize: '2rem', color: '#ff5000' }}></MdOutlineAnnouncement> Editar Banner
                            </MDBCard>
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_leads')}>
                                <TbDiscount2 style={{ fontSize: '2rem', color: '#ff5000' }}></TbDiscount2> Visualizar Leads
                            </MDBCard>
                            <MDBCard className="dashboardMainCards" onClick={() => setView('dashboard_promo')}>
                                <MdOutlineDiscount style={{ fontSize: '2rem', color: '#ff5000' }}></MdOutlineDiscount> Gerenciar Promoções
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow fluid style={{ position: 'absolute', bottom: '25px', right: '25px', padding: '0' }}>
                        <img src={Logo} alt='imagem de sobre nós 1' style={{ width: '200px' }} ></img>
                    </MDBRow>
                </MDBContainer>
                : view === 'dashboard_add' ?
                    <DashboardAdd />
                    : view === 'dashboard_remove' ?
                        <DashboardRemove />
                        : view === 'dashboard_edit' ?
                            <DashboardEdit />
                            : view === 'dashboard_banners' ?
                                <DashboardBanners />
                                : view === 'dashboard_leads' ?
                                    <DashboardLeads />
                                    : view === 'dashboard_promo' ?
                                        <DashboardPromo />
                                        :
                                        null
            }
        </div>
    );
}

export default Dashboard;