import { useEffect, useState } from 'react';
import Navbar, { MobileNavbar } from '../component/navbar'
import Footer from '../component/footer'
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import 'swiper/css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { GaragemConceito } from "../component/Icons";
import { leadAdd } from '../controllers/leadController';
import { TbDiscount2 } from "react-icons/tb";

const Usuario = () => {
    const [email, setEmail] = useState('');
    const [nome, setNome] = useState('');
    const [celular, setCelular] = useState('');
    const [notificationStatus, setNotificationStatus] = useState({ message: '', status: true, mode: 'form' });
    const [buttonDisabled, setButtonDisabled] = useState(true); // Initialize button as disabled

    const handleSubmit = async (e) => {
        setNotificationStatus({ ...notificationStatus, mode: 'status' })
        e.preventDefault();
        const result = await leadAdd(nome, email, celular);

        if (!result.success) {
            setNotificationStatus({ message: 'Ops, houve um erro! Tente novamente.', status: false });
            return;
        }

        setNotificationStatus({ message: 'Parabéns, seu cadastro foi realizado com sucesso!', status: true });
    };

    useEffect(() => {
        document.title = "Cadastre e Ganhe! - Garagem Conceito";
        window.scrollTo(0, 0);
        AOS.init();

        // Check if all three fields have content to enable the submit button
        if (nome && email && celular) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [nome, email, celular]); // Update button status when fields change

    const handleTelChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        setCelular(inputValue);
    };

    return (
        <div>
            <Navbar />
            <MobileNavbar />
            <MDBContainer className="cadastro-container">
                <MDBRow className="box-section justify-content-center">
                    <MDBCol className='box-section-col-info'>
                        <h1>Cadastre e Ganhe</h1>
                        <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>Faça seu cadastro abaixo e fique por dentro das novidade,
                            promoções e muito mais! Além disso, você ainda ganha um super cupom de 20% OFF em todas as peças que estão
                            disponíveis em nosso site! </p>

                        {notificationStatus.mode === 'form' ?

                            <form onSubmit={handleSubmit} className="dashboardForm">

                                <div className="mb-3 dashboardInput">
                                    <label className="description-text">Seu nome completo</label>
                                    <input
                                        type="text"
                                        placeholder="seu nome completo"
                                        value={nome}
                                        onChange={(e) => setNome(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3 dashboardInput">
                                    <label className="description-text">Seu endereço de e-mail</label>
                                    <input
                                        type="email"
                                        placeholder="seu endereço de e-mail"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4 dashboardInput">
                                    <label className="description-text">Seu número de celular</label>
                                    <input
                                        type="tel"
                                        placeholder="seu numero de celular"
                                        value={celular}
                                        onChange={handleTelChange} // Use the handleTelChange function
                                    />
                                </div>
                                <div className='btn-area'>
                                    <MDBBtn className="confirm-btn shadow-0" type="submit" disabled={buttonDisabled}>
                                        Cadastre e Adquira seu Cupom
                                    </MDBBtn>
                                </div>
                            </form>
                            : notificationStatus.status ?
                                <div className='notification-status-cupom'>
                                    <p><span className='icon'><TbDiscount2 /></span>✨ Parabéns e Seja Muito Bem-Vindo(a)! 🎉
                                        <p></p>Você acaba de garantir um desconto EXCLUSIVO de 20% em sua primeira compra como agradecimento pelo seu cadastro! 🌟
                                        <p></p>Use seu prêmio até 30/04 e sinta-se realmente premiado(a) por escolher estar conosco.
                                        <p></p>🛍️ Desfrute do Seu Desconto: Na hora de finalizar a compra, seu desconto será aplicado de forma automática. É simples assim!
                                        <p></p><b>Desconto válido somente na condição de pagamento à vista, sendo 60% ato e 40% na entrega</b></p>
                                </div>
                                : !notificationStatus.status ?
                                    <div className='notification-status-cupom'>
                                        <p>Ops, houve um erro, atualize a página e tente novamente!</p>
                                    </div>
                                    :
                                    null
                        }
                    </MDBCol>
                    <MDBCol className='cadastro-ilu'>
                        <GaragemConceito />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </div>
    );
};

export default Usuario;
