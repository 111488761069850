import {useEffect} from 'react';
import Navbar, { MobileNavbar } from '../component/navbar'
import Footer from '../component/footer'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import HeaderTop from '../img/HeaderTop.svg';

function Contato () {
    useEffect(() => {
        document.title = "Contato | Garagem Conceito"
        window.scrollTo(0,0);

    }, [])
    return (
        <div>
            <Navbar />
            <MobileNavbar />
            <div className='headerPages'>
                <img src={HeaderTop}></img>
            </div>
            <div className='header-separator' style={{width:'100%', height:'3rem', backgroundColor:'#161616'}}><b>Contato</b></div>
            <MDBContainer className='contactFormHolder'>
                <MDBRow className='contactForm justifyCenter'>
                    <h1 className='contentTitle'>Envie um E-Mail</h1>
                    <label>Nome</label>
                    <input></input>
                    <MDBRow className='contactForm2 mobile-hidden'>
                        <label>Telefone</label>
                        <label>E-Mail</label>
                        <input></input>
                        <input style={{marginLeft:'10px'}}></input>
                    </MDBRow>
                    <MDBRow className='pc-hidden contactForm2'>
                        <label>Telefone</label>
                        <input></input>
                        <label>E-Mail</label>
                        <input></input>
                    </MDBRow>
                    <label>Cidade</label>
                    <input></input>
                    <label>Mensagem</label>
                    <textarea rows={15}></textarea>
                    <a className='mailContactBtn' style={{marginTop:'70px'}}>Enviar</a>
                    <h1 className='contentTitle' style={{fontSize:'1rem'}}>Ou entre em contato via whatsapp!</h1>
                    <a className='wppContactBtn'>(11) 0 0000-0000</a>  
                </MDBRow>
            </MDBContainer>
            <Footer />
        </div>
    )
}

export default Contato;