import React, { useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBCard } from 'mdb-react-ui-kit';
import { api, getTokenFromLocalStorage } from "../controllers/authController";

function ProductCard({ produto }) {
    if (!produto) {
        return <p>Produto não encontrado.</p>;
    }

    const thumbnailUrl = produto.img_url.split(',')[produto.thumbnail_index];

    return (
        <MDBCard data-aos="fade-up" className="productCard3" style={{ cursor: 'pointer' }}>
            <img src={api + `/${thumbnailUrl}`} style={{ zIndex: '0' }} alt={produto.nome} />
            <h4 className='productCardText2'>{produto.nome} - {produto.material}</h4>
        </MDBCard>
    );
}

function ListaProdutosEdit({ onProductSelect, atualizarLista, notificarAtualizacao }) {
    const [produtos, setProdutos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0);

    useEffect(() => {
        if (atualizarLista) {
            carregarProdutos();
            notificarAtualizacao();
        } else {
            fetch(`${api}/produtos?page=${paginaAtual}&limit=15`)
                .then(response => response.json())
                .then(data => {
                    setProdutos(data.produtos);
                    setTotalPaginas(Math.ceil(data.totalItems / 15));
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Erro ao buscar produtos:', error);
                    setLoading(false);
                });
        }
    }, [paginaAtual, atualizarLista, notificarAtualizacao]);

    const carregarProdutos = (page = paginaAtual) => {
        setLoading(true);
        fetch(`${api}/produtos?page=${page}&limit=15`)
            .then(response => response.json())
            .then(data => {
                setProdutos(data.produtos);
                setTotalPaginas(Math.ceil(data.totalItems / 15));
                setLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar produtos:', error);
                setLoading(false);
            });
    };

    const handleProductClick = (produto) => {
        onProductSelect(produto);
    };

    if (loading) {
        return <p>Carregando produtos...</p>;
    }

    return (
        <>
            <MDBCol md={12} className='productDelete' style={{ padding: '0' }}>
                {produtos.length > 0 ? (
                    produtos.map(produto => (
                        <div key={produto.id} onClick={() => onProductSelect(produto)}>
                            <ProductCard key={produto.id} produto={produto} />
                        </div>
                    ))
                ) : (
                    <p>Nenhum produto encontrado.</p>
                )}
            </MDBCol>
            <MDBRow className='d-flex justify-content-center' style={{ width: '99%', marginTop: '25px' }}>
                <div className="pagination-controls" style={{ width: '60%' }}>
                    <button onClick={() => setPaginaAtual(p => Math.max(1, p - 1))} disabled={paginaAtual === 1}>
                        Anterior
                    </button>
                    <span>Página {paginaAtual} de {totalPaginas}</span>
                    <button onClick={() => setPaginaAtual(p => Math.min(p + 1, totalPaginas))} disabled={paginaAtual === totalPaginas}>
                        Próximo
                    </button>
                </div>
            </MDBRow>
        </>
    );
}


export default ListaProdutosEdit;