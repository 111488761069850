import { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { api, getTokenFromLocalStorage } from "../controllers/authController";
import '../index.css';

function DashboardBanners() {
    const [banners, setBanners] = useState([]);
    const [file, setFile] = useState(null);
    const [link, setLink] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');

    useEffect(() => {
        // Fetch banners from the server upon component mount
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await fetch(`${api}/banners`);
            const data = await response.json();
            setBanners(data.banners);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file); // Update the file state

        // Check if a file is selected
        if (file) {
            // Create a FileReader instance
            const reader = new FileReader();

            // Define the onload event handler
            reader.onload = (e) => {
                // Set the preview URL to the result of FileReader
                setPreviewUrl(e.target.result);
            };

            // Read the file as a data URL
            reader.readAsDataURL(file);
        } else {
            setPreviewUrl(null); // Clear the preview URL if no file is selected
        }
    };

    const handleLinkChange = (e) => {
        setLink(e.target.value);
    };

    const handleCreateBanner = async () => {
        if (!file) {
            console.error('No file selected');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('link', link);

        try {
            const token = getTokenFromLocalStorage();
            if (!token) {
                console.error('Token not found. User is not authenticated.');
                return;
            }

            const response = await fetch(`${api}/createBanner`, {
                method: 'POST',
                headers: {
                    Authorization: `${token}`
                },
                body: formData,
            });

            if (response.ok) {
                // Refresh banners after successfully creating a new one
                fetchBanners();
                console.log('Banner created successfully');
            } else {
                console.error('Failed to create banner');
            }
        } catch (error) {
            console.error('Error creating banner:', error);
        }
    };

    const handleDeleteBanner = async (bannerId) => {
        try {
            const token = getTokenFromLocalStorage();
            if (!token) {
                console.error('Token not found. User is not authenticated.');
                return;
            }

            const response = await fetch(`${api}/deleteBanner/${bannerId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `${token}`
                },
            });

            if (response.ok) {
                // Refresh banners after successfully deleting one
                fetchBanners();
                console.log('Banner deleted successfully');
            } else {
                console.error('Failed to delete banner');
            }
        } catch (error) {
            console.error('Error deleting banner:', error);
        }
    };

    return (
        <div>
            <MDBContainer className="dashboard">
                <MDBRow className="dashboardRow d-flex justify-content-center">
                    <h2>Gerenciamento de Banners</h2>
                    <h6>Gerencie os Banners da página principal no painel abaixo</h6>
                    <MDBCol md={6} className="dashboardCol box-section">
                        <MDBRow className="d-flex justify-content-center" style={{ border: 'none', marginTop: '0', padding: '15px' }} >
                            {previewUrl && <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px', marginBottom: '10px' }} />}
                            <label >Insira a imagem desejada</label>
                        </MDBRow>
                        <input style={{ width: 'auto' }} className="upload-btn" type="file" onChange={handleFileChange} />
                        <MDBRow className="d-flex justify-content-center" style={{padding:'15px'}} >
                            <label > Insira o link desejado para o banner</label>
                            <MDBInput label="Link" value={link} onChange={handleLinkChange} />
                            <MDBBtn className="confirm-btn" onClick={handleCreateBanner} style={{ marginTop: '15px' }} >Criar Banner</MDBBtn>
                        </MDBRow>
                        <MDBRow className="d-flex justify-content-center">
                            {banners.map(banner => (
                                <MDBCol key={banner.id} className="dashboard-banners-display">
                                    <img src={api + `/bannerImgs/${banner.image_url}`} alt="Banner" />
                                    <MDBBtn className="confirm-btn-2" onClick={() => handleDeleteBanner(banner.id)}>Deletar</MDBBtn>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default DashboardBanners;