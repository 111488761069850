import { api } from "./authController";

async function leadAdd(nome, email, telefone) {
    try {
        let response = await fetch(api + "/leads/add", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                nome, 
                email,
                telefone
            })
        });

        const data = await response.json();

        if (!response.ok ) {
            return data;
        }

        // Salvar o token no localStorage
        localStorage.setItem('authToken', JSON.stringify(data.token)); // Ajuste para salvar somente o token

        return data;
    } catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export { leadAdd }