import { useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { api, getTokenFromLocalStorage } from "../controllers/authController";
import '../index.css';

function DashboardAdd() {

    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [category, setCategory] = useState('');
    const [material, setMaterial] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedImagePreviews, setSelectedImagePreviews] = useState([]);
    const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(0);
    const [productInfo, setProductInfo] = useState('');
    const [productValue, setProductValue] = useState('');
    const [productPromoValue, setProductPromoValue] = useState('');

    const handleThumbnailSelect = (index) => {
        setSelectedThumbnailIndex(index);
    };

    const handleFileChange = (e) => {
        if (e.target.files) {
            const newFiles = Array.from(e.target.files);
            setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);

            const filePreviews = newFiles.map(file => URL.createObjectURL(file));
            setSelectedImagePreviews(prevPreviews => [...prevPreviews, ...filePreviews]);

            // Clean up URLs when the component is unmounted
            return () => filePreviews.forEach(URL.revokeObjectURL);
        }
    };

    const handleImageRemove = (indexToRemove) => {
        const updatedPreviews = selectedImagePreviews.filter((_, index) => index !== indexToRemove);
        setSelectedImagePreviews(updatedPreviews);

        // Update selectedFiles by filtering out the file at the corresponding index
        const updatedFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
        setSelectedFiles(updatedFiles);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!productName || !productDescription || !category || !material) {
            alert('Por favor, preencha todos os campos (Nome, Descrição, Categoria e Material) do produto.');
            return; // Interromper a execução da função se algum campo estiver vazio
        }

        const formData = new FormData();
        formData.append('nome', productName);
        formData.append('descricao', productDescription);
        formData.append('info', productInfo); // Adiciona "info" ao formData
        formData.append('valor', productValue); // Adiciona "valor" ao formData
        formData.append('valor_promo', productPromoValue); // Adiciona "valor_promo" ao formData
        formData.append('categoria', category);
        formData.append('material', material);
        formData.append('selectedThumbnailIndex', selectedThumbnailIndex);

        if (selectedFiles) {
            for (const file of selectedFiles) {
                formData.append('imagens', file);
            }
        }

        try {
            // Obter o token do localStorage
            const token = getTokenFromLocalStorage(); // Supondo que esta função retorne o token diretamente

            if (!token) {
                console.error('Token não encontrado. O usuário não está autenticado.');
                alert('Usuário não autorizado.');
                window.location.href = '/dashboard/login';
                return;
            }

            const response = await fetch(`${api}/cadastro`, {
                method: 'POST',
                headers: {
                    // Adicionar o token Bearer ao cabeçalho Authorization
                    Authorization: `${token}`
                },
                body: formData,
            });

            if (!response.ok) {
                alert('Não foi possível adicionar o produto. Tente novamente.');
                return;
            }

            // Exibe um alerta de sucesso
            alert('Produto adicionado com sucesso!');

            // Recarrega a página
            window.location.reload();
        } catch (error) {
            console.error('Erro ao adicionar o produto:', error);
            alert('Erro ao adicionar o produto.');
        }
    };

    return (
        <div>
            <MDBContainer className="dashboard">
                <MDBRow className="dashboardRow">
                    <h2>CADASTRO DE PRODUTO</h2>
                    <form onSubmit={handleSubmit} className="dashboardForm">
                        <MDBCol className="dashboardCol">
                            <h6>Para cadastrar seu produto, insira as informações abaixo</h6>
                            <MDBRow md={12} className="box-section" style={{ width: '90%' }}>
                                <MDBCol md={12}>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Nome do Produto</label>
                                        <input
                                            id="productName"
                                            type="text"
                                            placeholder="Ex:Balanço folha, Balanço Bowl, Etc"
                                            value={productName}
                                            onChange={(e) => setProductName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Descrição do Produto</label>
                                        <textarea
                                            label="Descrição do Produto"
                                            id="productDescription"
                                            rows={5}
                                            maxLength="2000"
                                            value={productDescription}
                                            onChange={(e) => setProductDescription(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Informações Adicionais</label>
                                        <textarea
                                            label="Informações Adicionais"
                                            id="productInfo"
                                            rows={3}
                                            maxLength="1000"
                                            value={productInfo}
                                            onChange={(e) => setProductInfo(e.target.value)}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol md={6}>
                                    <div className="dashboardInput">
                                        <label className="description-text">Categoria do Produto</label>
                                        <label className="dashboardRadius">Selecione uma categoria</label>
                                        <select value={category} onChange={(e) => setCategory(e.target.value)} className="dashboardRadius">
                                            <option value="" disabled selected>Selecione uma categoria</option>
                                            <option value="Balanço">Balanço</option>
                                            <option value="Poltrona">Poltrona</option>
                                            <option value="Sofá">Sofá</option>
                                            <option value="Concha">Chaise e Concha</option>
                                            <option value="Mesa">Mesa</option>
                                            <option value="Balcão">Aparador e Balcão</option>
                                            <option value="Cadeira">Cadeira</option>
                                            <option value="Espreguiçadeira">Espreguiçadeira</option>
                                            <option value="Banqueta">Banqueta</option>
                                            <option value="Mesa de Centro">Mesa de Centro</option>
                                            <option value="Banco">Puff e Banco</option>
                                            <option value="Ombrelone">Ombrelone</option>
                                        </select>
                                    </div>
                                </MDBCol>
                                <MDBCol md={6}>
                                    <div className="dashboardInput">
                                        <label className="description-text">Material do Produto</label>
                                        <label className="dashboardRadius">Selecione o material de fabricação</label>
                                        <select value={material} onChange={(e) => setMaterial(e.target.value)} className="dashboardRadius">
                                            <option value="" disabled selected>Selecione um material</option>
                                            <option value="Corda Náutica">Corda / Tricô Náutico</option>
                                            <option value="Fibra Sintética">Fibra Sintética</option>
                                            <option value="Industrial">Industrial</option>
                                            <option value="Madeira">Madeira</option>
                                        </select>
                                    </div>
                                </MDBCol>
                                <MDBCol md={6}>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Valor</label>
                                        <input
                                            id="productValue"
                                            type="number"
                                            placeholder="Ex: 1000.00"
                                            value={productValue}
                                            onChange={(e) => setProductValue(e.target.value)}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol md={6}>
                                    <div className="mb-4 dashboardInput">
                                        <label className="description-text">Valor Promocional</label>
                                        <input
                                            id="productPromoValue"
                                            type="number"
                                            placeholder="Ex: 800.00"
                                            value={productPromoValue}
                                            onChange={(e) => setProductPromoValue(e.target.value)}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol md={12} className="dashboardCol image-view-holder" style={{ width: '100%' }}>
                                    <div className="image-preview">
                                        {selectedImagePreviews.map((image, index) => (
                                            <div>
                                                <div key={index} className={`thumbnail-container ${index === selectedThumbnailIndex ? 'selected' : ''}`}>
                                                    {index === selectedThumbnailIndex && <div className="thumbnail-text">Thumbnail</div>}
                                                    <img
                                                        src={image}
                                                        alt="Preview"
                                                        style={{ width: "100px", height: "100px", margin: "10px", borderRadius: '15px' }}
                                                        onClick={() => handleThumbnailSelect(index)}
                                                    />
                                                </div>
                                                <MDBBtn className="remove-btn" onClick={() => handleImageRemove(index)}>Remover</MDBBtn>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="productImages">Imagens do Produto</label>
                                        <input
                                            className="upload-btn"
                                            type="file"
                                            id="productImages"
                                            multiple
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <MDBBtn className="confirmBtn" type="submit">CONFIRMAR E ADICIONAR PRODUTO</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </form>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default DashboardAdd;