import React, { useEffect } from 'react';
import '../Styles/instagram.css';

const Instagram = () => {
    useEffect(() => {
        const feed = new window.Instafeed({
            get: 'user',
            userId: '17841405793187218', // ID do usuário Instagram da Garagem Conceito
            clientId: 'YOUR_CLIENT_ID', // Opcional na versão 1.4.1
            accessToken: 'YOUR_ACCESS_TOKEN', // Opcional, se disponível
            resolution: 'low_resolution',
            limit: 6,
            template: `
                <div class="instagram-post">
                    <a href="{{link}}" target="_blank">
                        <img src="{{image}}" alt="{{caption}}" class="instagram-img"/>
                    </a>
                </div>
            `,
            error: function (err) {
                console.error('Erro ao carregar o feed do Instagram:', err);
            }
        });
        feed.run();
    }, []);

    return (
        <div className="instagram-container">
            <h2>Nos siga no <span className="highlight">Instagram</span></h2>
            <div id="instafeed" className="instagram-posts"></div>
            <div class="elfsight-app-0acd6432-9e86-4483-b628-05e26bb5b054" data-elfsight-app-lazy></div>
        </div>
    );
}

export default Instagram;
