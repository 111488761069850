import { useState, useEffect } from "react";
import React from 'react';
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBNavbar, MDBNavbarNav, MDBNavbarItem } from 'mdb-react-ui-kit';
import { TbDiscount2 } from "react-icons/tb";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import { MdEdit, MdOutlineDesignServices } from "react-icons/md";
import { BiMessageSquareDetail } from "react-icons/bi";
import { TbSofa } from "react-icons/tb";
import { GrGroup } from "react-icons/gr";
import { RiHomeLine } from "react-icons/ri";
import { GaragemConceito } from "./Icons";
import { FaRegUser } from "react-icons/fa6";

function NavBar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    // Adiciona o event listener
    window.addEventListener("scroll", handleScroll);

    // Limpa o event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    maxWidth: '100%',
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
    transition: 'padding 0.3s ease-in-out', // Transição suave
    padding: scrolled ? '8px 13px' : '10px 15px', // Padding menor quando rolar
  };

  const navbarBtnStyle = {
    border: '0.1rem solid #1e1e1e40',
  };

  const navbarBgStyle = {
    backgroundColor: '#FFF',
    border: '0.1px solid #ebebeb'
  };

  const homeText = scrolled ? "" : "Início";
  const aboutUsText = scrolled ? "" : "A Garagem";
  const productsText = scrolled ? "" : "Produtos";
  const servicesText = scrolled ? "" : "Serviços";
  const contactText = scrolled ? "" : "Fale Conosco";
  const promoText = scrolled ? "" : "Promoções";

  return (
    <>
      <div className="navbar-container mobile-hidden" style={navbarBgStyle}>
        <MDBNavbar expand='lg' className="no-box-shadow" style={navbarStyle}>
          <MDBRow className="navbar-row">
            <MDBCol>
              <a href='/' className={scrolled ? 'logo-icon scrolled' : 'logo-icon'}>
                <GaragemConceito />
              </a>
            </MDBCol>
            <MDBCol md={10}>
              <MDBContainer className="navbar-content">
                <MDBNavbarNav center fullWidth={true} className='mb-2 mb-lg-0 navbar-content-items'>
                  <MDBNavbarItem>
                    <Link className={'navBarButtons'} to='/' style={navbarBtnStyle}>
                      <RiHomeLine /> {homeText && <span className="navbar-text">{homeText}</span>}
                    </Link>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <Link className={'navBarButtons'} to='/loja' style={navbarBtnStyle}>
                      <TbSofa /> {productsText && <span className="navbar-text">{productsText}</span>}
                    </Link>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a className={'navBarButtons'} href='/servicos' style={navbarBtnStyle}>
                      <MdOutlineDesignServices /> {servicesText && <span className="navbar-text">{servicesText}</span>}
                    </a>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a className={'navBarButtons'} href='/sobre-nos' style={navbarBtnStyle}>
                      <GrGroup /> {aboutUsText && <span className="navbar-text">{aboutUsText}</span>}
                    </a>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a className={'navBarButtons'} href='/#contato' style={navbarBtnStyle}>
                      <BiMessageSquareDetail /> {contactText && <span className="navbar-text">{contactText}</span>}
                    </a>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a className={'navBarButtons'} href='/cadastre-e-ganhe' style={navbarBtnStyle}>
                      <TbDiscount2 /> {promoText && <span className="navbar-text">{promoText}</span>}
                    </a>
                  </MDBNavbarItem>
                </MDBNavbarNav>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBNavbar>
      </div>
    </>
  );
}

function MobileNavbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <>
      <div className="navbar-container pc-hidden">
        <MDBNavbar expand='lg' className="no-box-shadow">
          <MDBRow className="navbar-row">
            <MDBCol size={2}>
              <a href='/' className={'logo-icon scrolled'}>
                <GaragemConceito />
              </a>
            </MDBCol>
            <MDBCol size={10}>
              <MDBContainer className="navbar-content">
                <MDBNavbarNav center fullWidth={true} className='mb-2 mb-lg-0 navbar-content-items'>
                  <MDBNavbarItem>
                    <Link className={'navBarButtons mobile'} to='/' >
                      <RiHomeLine />
                    </Link>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <Link className={'navBarButtons mobile'} to='/loja' >
                      <TbSofa />
                    </Link>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a className={'navBarButtons mobile'} href='/servicos' >
                      <MdOutlineDesignServices />
                    </a>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a className={'navBarButtons mobile'} href='/sobre-nos' >
                      <GrGroup />
                    </a>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a className={'navBarButtons mobile'} href='/#contato' >
                      <BiMessageSquareDetail />
                    </a>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <a className={'navBarButtons mobile'} href='/cadastre-e-ganhe' >
                      <TbDiscount2 />
                    </a>
                  </MDBNavbarItem>
                </MDBNavbarNav>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBNavbar>
      </div>
    </>
  )
}

function NavbarDashboard() {
  const [view, setView] = useState('dashboard');

  return (
    <div className="navbarDashboard">
      <MDBRow>
        <MDBCol className="navbarDashboardCol">
          <a onClick={() => setView('dashboard_add')}><IoMdAdd className="navbarIcon" /></a>
          <a><IoMdRemove className="navbarIcon" /></a>
          <a><MdEdit className="navbarIcon" /></a>
        </MDBCol>
      </MDBRow>
    </div>
  )
}

export default NavBar;
export { NavbarDashboard };
export { MobileNavbar };